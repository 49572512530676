// starShapes.js

let starShapes = {};

function calculateStarPoints(centerX, centerY, arms, outerRadius, innerRadius) {
  let points = [];
  const angle = Math.PI / arms;

  for (let i = 0; i < 2 * arms; i++) {
    const r = i % 2 === 0 ? outerRadius : innerRadius;
    const currX = centerX + Math.cos(i * angle) * r;
    const currY = centerY + Math.sin(i * angle) * r;
    points.push(currX, currY);
  }

  return points.join(' ');
}

function initStarShapes() {
  for (let arms = 4; arms <= 8; arms++) {
    for (let outerRadius = 1; outerRadius <= 5; outerRadius++) {
      const innerRadius = outerRadius / 2;
      starShapes[`${arms}-${outerRadius}`] = calculateStarPoints(0, 0, arms, outerRadius, innerRadius);
    }
  }
}

initStarShapes();

export default starShapes;
