
// import React, { createContext, useState, useEffect } from 'react';

// export const SvgDimensionsContext = createContext();

// export const SvgDimensionsProvider = ({ children }) => {
//     const getInitialDimensions = () => {
//         const containerWidth=window.innerWidth;
//         const containerHeight=window.innerHeight;
//         const svgWidth = window.innerWidth/2;
//         const svgHeight = window.innerHeight/2;
//         const maxDimension = Math.min(svgWidth, svgHeight);

//         // Calculate maximum allowed radii
//         const maxEarthOrbitRadius = maxDimension / 3; // Adjust as needed
//         const maxMoonOrbitRadius = maxEarthOrbitRadius / 4; // Adjust as needed

//         return {
//             containerWidth,
//             containerHeight,
//             svgWidth,
//             svgHeight,
//             sunCenter: { x: svgWidth / 2, y: svgHeight / 2 },
//             sunRadius: 40, // This can be adjusted as needed
//             earthOrbitRadius: Math.min(200, maxEarthOrbitRadius), // Default or max, whichever is smaller
//             moonOrbitRadius: Math.min(50, maxMoonOrbitRadius), // Default or max, whichever is smaller
//             earthRadius: 25, // This can be adjusted as needed
//             moonRadius: 7 // This can be adjusted as needed
//         };
//     };

//     const [dimensions, setDimensions] = useState(getInitialDimensions());

//     useEffect(() => {
//         const handleResize = () => {
//             const newWidth = window.innerWidth;
//             const newHeight = window.innerHeight;
//             const maxDimension = Math.min(newWidth, newHeight);
//             const newSvGHeight=newHeight/2;
//             const newSVGWidth=newWidth/2;
//             const maxEarthOrbitRadius = maxDimension / 3;
//             const maxMoonOrbitRadius = maxEarthOrbitRadius / 4;

//             setDimensions({
//                 containerWidth:newWidth,
//                 containerHeight:newHeight,
//                 svgWidth: newSVGWidth,
//                 svgHeight: newSvGHeight,
//                 sunCenter: { x: newSVGWidth / 2, y: newSvGHeight / 2 },
//                 sunRadius: 40,
//                 earthOrbitRadius: Math.min(200, maxEarthOrbitRadius),
//                 moonOrbitRadius: Math.min(50, maxMoonOrbitRadius),
//                 earthRadius: 25,
//                 moonRadius: 7
//             });
//         };

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return (
//         <SvgDimensionsContext.Provider value={dimensions}>
//             {children}
//         </SvgDimensionsContext.Provider>
//     );
// };










import React, { createContext, useState, useEffect } from 'react';

export const SvgDimensionsContext = createContext();

export const SvgDimensionsProvider = ({ children }) => {
    const calculateDimensions = () => {
        const containerWidth = window.innerWidth;
        const containerHeight = window.innerHeight;
        const svgWidth = containerWidth / 1;
        const svgHeight = containerHeight / 1;
        const maxDimension = Math.min(svgWidth, svgHeight);

        // Proportional calculations
        const sunRadius = maxDimension * 0.1; // Sun radius is 10% of maxDimension
        const earthOrbitRadius = maxDimension * 0.3; // Earth orbit radius is 30% of maxDimension
        const moonOrbitRadius = earthOrbitRadius * 0.3; // Moon orbit radius is 30% of earthOrbitRadius
        const earthRadius = sunRadius * 0.4; // Earth radius is 50% of sunRadius
        const moonRadius = earthRadius * 0.27; // Moon radius is 27% of earthRadius

        return {
            containerWidth,
            containerHeight,
            containerCenter:{ x: containerWidth / 2, y: containerHeight / 2 },
            svgWidth,
            svgHeight,
            sunCenter: { x: svgWidth / 2, y: svgHeight / 2 },
            sunRadius,
            earthOrbitRadius,
            moonOrbitRadius,
            earthRadius,
            moonRadius
        };
    };

    const [dimensions, setDimensions] = useState(calculateDimensions());

    useEffect(() => {
        const handleResize = () => {
            setDimensions(calculateDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <SvgDimensionsContext.Provider value={dimensions}>
            {children}
        </SvgDimensionsContext.Provider>
    );
};
