// starGenerator.js
import starShapes from './starShapes'; // Import precalculated shapes

export function generateStars(numStars, minRadius, containerCenter, svgWidth, svgHeight) {
    const stars = [];
    const maxDimension = Math.max(svgWidth, svgHeight); // Use the bigger of width or height

    for (let i = 0; i < numStars; i++) {
        let outerRadius = Math.random() * 5 + 1; // Random radius between 1 and 6
        const innerRadius = outerRadius / 2; // Inner radius is half of the outer radius
        let radius, cx, cy;

        do {
            radius = minRadius + Math.random() * (maxDimension / 2 - minRadius - outerRadius);
            const angle = Math.random() * 2 * Math.PI;
            cx = containerCenter.x + radius * Math.cos(angle);
            cy = containerCenter.y + radius * Math.sin(angle);
        } while (cx - outerRadius < 0 || cx + outerRadius > svgWidth ||
                 cy - outerRadius < 0 || cy + outerRadius > svgHeight);

                 const shapeKey = Object.keys(starShapes)[Math.floor(Math.random() * Object.keys(starShapes).length)];
                 const points = starShapes[shapeKey];
         
                 stars.push({ cx: cx, cy: cy, points: points });
    }
    return stars;
}