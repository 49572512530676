import React, { useState, useEffect, useContext } from 'react';
import { SvgDimensionsContext } from './dimensia'; // Adjust the import path as needed
const Weather = ({ location='' }) => {
    const [weather, setWeather] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const { svgWidth, svgHeight, containerWidth } = useContext(SvgDimensionsContext);

    useEffect(() => {
        const fetchWeather = async () => {
            var urlwttr=`https://wttr.in/${location.replace(" ", "+")}`;
            try {
                if(location===''){
                    urlwttr=`https://wttr.in?`;
                }
                const response = await fetch(urlwttr);
                if (response.ok) {
                    const text = await response.text();
                    const preTagContent = extractPreTagContent(text);
                    console.log(preTagContent)
                    setWeather(preTagContent);
                } else {
                    setWeather('Unable to fetch weather');
                }
            } catch (error) {
                setWeather('Error fetching weather');
            }
        };

        fetchWeather();
    }, [location]);

    // Function to extract content inside <pre> tag
    const extractPreTagContent = (html) => {
        const preTagPattern = /<pre>([\s\S]*?)<\/pre>/;
        const match = html.match(preTagPattern);
        return match ? match[1] : 'No weather data available';
    };

    return (
            <div>
            <button onClick={() => setShowPopup(!showPopup)} style={{color:"rgb(7, 252, 178)",backgroundColor:"transparent"}}>Weather</button>
            {showPopup && (
                <div className="weather-popup" style={{width:"fit-content"}}>
                <pre dangerouslySetInnerHTML={{ __html: weather }} />
            </div>
            )}
        </div>
            
       
        
    );
};

export default Weather;



