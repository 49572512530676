import React, { useContext, useEffect, useState, createContext } from 'react';
import { SvgDimensionsProvider, SvgDimensionsContext } from './dimensia'; // Ensure this path is correct
import Stars from './stars';//importing stars
import Weather from './weather'; // Adjust the import path as needed
const TimeContext = createContext();

export default function App() {
  return (
    <SvgDimensionsProvider>
        <SolarClockSVG />
    </SvgDimensionsProvider>
);
}

 function SolarClockSVG() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const location="witeithie"
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const { svgWidth, svgHeight,containerWidth,containerHeight } = useContext(SvgDimensionsContext); // Use the context here

  return (
   
      <TimeContext.Provider value={currentTime}>
        <div style={{ width: containerWidth, height: containerHeight }} className='containerDiv'>
          <svg width={svgWidth} height={svgHeight}>
          <Stars currentTime={currentTime} />
              <Sun />
              <Earth />
              <Moon />
              <Compass />
          </svg>
          <Weather location={location} /> 
          </div>
      </TimeContext.Provider>
  );
}




function Sun(){
  var currentTime=useContext(TimeContext);
  const { sunCenter,sunRadius, earthOrbitRadius } = useContext(SvgDimensionsContext);  // Immutable constants
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const currentDay = days[currentTime.getDay()];
  const currentHour = currentTime.getHours();
  const formattedHour = String(currentHour).padStart(2, '0');
  // Calculate positions for the Sun's orbit marks
  const markPositions = {
    top: { x: sunCenter.x, y: sunCenter.y - earthOrbitRadius },
    right: { x: sunCenter.x + earthOrbitRadius, y: sunCenter.y },
    bottom: { x: sunCenter.x, y: sunCenter.y + earthOrbitRadius },
    left: { x: sunCenter.x - earthOrbitRadius, y: sunCenter.y }
};

  return (
    <g>
       <defs>
        {/* adding the suns glow */}
    <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
      <feGaussianBlur stdDeviation="20" result="coloredBlur"/>
      <feMerge>
        <feMergeNode in="coloredBlur"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
    </defs>

      {/* Sun and Day */}
         <circle cx={sunCenter.x} cy={sunCenter.y} r={sunRadius} fill="yellow" filter="url(#glow)" />
            {/* Marks for the Sun's Orbit */}
                {/* Marks for the Sun's Orbit */}
            <circle cx={markPositions.top.x} cy={markPositions.top.y} r="3" fill="yellow" />
            <circle cx={markPositions.right.x} cy={markPositions.right.y} r="3" fill="yellow" />
            <circle cx={markPositions.bottom.x} cy={markPositions.bottom.y} r="3" fill="yellow" />
            <circle cx={markPositions.left.x} cy={markPositions.left.y} r="3" fill="yellow" />

      <text x={sunCenter.x} y={sunCenter.y} textAnchor="middle" dominantBaseline="middle">
        {formattedHour}
      </text>
      <text x={sunCenter.x} y={sunCenter.y+100} textAnchor="middle" dominantBaseline="middle" fill='white'>
        {currentDay}
      </text>
    </g>
  );
}//end of sun component


// the earth component representing the minutes
function Earth(){
var currentTime=useContext(TimeContext);
const { sunCenter,sunRadius, earthOrbitRadius, moonOrbitRadius, earthRadius } = useContext(SvgDimensionsContext);    // Immutable constants
const currentMinute = currentTime.getMinutes();
const formattedMinute = String(currentMinute).padStart(2, '0');
const currentSecond = currentTime.getSeconds();
// handling earth's tilting
const earthTilt = 23.5;
// Calculate Earth's revolution angle based on minutes and seconds
const totalSecondsInMinute = currentMinute * 60 + currentSecond;
const earthRevolutionAngle = (totalSecondsInMinute / 3600) * 2 * Math.PI - Math.PI / 2;
// Rest of your existing code for position calculations
const earthX = sunCenter.x + earthOrbitRadius * Math.cos(earthRevolutionAngle);
const earthY = sunCenter.y + earthOrbitRadius * Math.sin(earthRevolutionAngle);
// earth shadow
const earthAngleToSun = Math.atan2(earthY - sunCenter.y, earthX - sunCenter.x) * (180 / Math.PI)+earthTilt;
// dealing with the axis of earth
const tipLength = 5; // Length of the visible tip
const topTipY = earthY - 25 - tipLength; // Position for the top tip
const bottomTipY = earthY + 25 + tipLength; // Position for the bottom tip
return(
  <g>
    <defs>
        {/* adding shadow on the earth */}
      <linearGradient id="dayNightGradient" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0.7" y2="0">
              <stop offset="0%" stopColor="green" />
              <stop offset="100%" stopColor="black" />
      </linearGradient>
    </defs>
   {/* Earth's Orbit */}
  <circle cx={sunCenter.x} cy={sunCenter.y} r={earthOrbitRadius} fill="none" stroke="gray" />
{/* Earth Circle */}
<circle cx={earthX} cy={earthY} r={earthRadius} fill="url(#dayNightGradient)"
      transform={`rotate(${earthAngleToSun } ${earthX} ${earthY})`} />
{/* the minute text */}
  <text x={earthX} y={earthY} textAnchor="middle" dominantBaseline="middle" fill='white'>
      {formattedMinute}
  </text>
{/* The spin axis */}
    <line x1={earthX} y1={topTipY} x2={earthX} y2={topTipY + tipLength} stroke="white" 
    strokeWidth="1" transform={`rotate(${earthTilt}, ${earthX}, ${earthY})`} />
    <line x1={earthX} y1={bottomTipY - tipLength} x2={earthX} y2={bottomTipY} stroke="white" 
    strokeWidth="1" transform={`rotate(${earthTilt}, ${earthX}, ${earthY})`} />
{/* Markers on the earths cardinal points */}
      <circle cx={earthX} cy={earthY - moonOrbitRadius} r="2" fill="red" /> {/* 12 position */}
      <circle cx={earthX + moonOrbitRadius} cy={earthY} r="2" fill="white"  /> {/* 3 position */}
      <circle cx={earthX} cy={earthY + moonOrbitRadius} r="2" fill="white" /> {/* 6 position */}
      <circle cx={earthX - moonOrbitRadius} cy={earthY} r="2" fill="white" /> {/* 9 position */}
{/* Moon's Orbit */}
<circle cx={earthX} cy={earthY} r={moonOrbitRadius} fill="none" stroke="lightgray" />
    </g>
  )
}// end of earth



// adding the moon to the svg
function Moon(){
var currentTime=useContext(TimeContext);
const { sunCenter,sunRadius, earthOrbitRadius, moonOrbitRadius, moonRadius } = useContext(SvgDimensionsContext);    // Immutable constants
const currentMinute = currentTime.getMinutes();
const currentSecond = currentTime.getSeconds();
const currentMillisecond=currentTime.getMilliseconds()
// Calculate Earth's revolution angle based on minutes and seconds
const totalSecondsInMinute = currentMinute * 60 + currentSecond;
const earthRevolutionAngle = (totalSecondsInMinute / 3600) * 2 * Math.PI - Math.PI / 2;
// code for position calculations
const earthX = sunCenter.x + earthOrbitRadius * Math.cos(earthRevolutionAngle);
const earthY = sunCenter.y + earthOrbitRadius * Math.sin(earthRevolutionAngle);
// calculate moons position
const fractionOfMinute = (currentSecond + currentMillisecond / 1000) / 60;
const moonTheta = fractionOfMinute * 2 * Math.PI - Math.PI / 2;
const moonX = earthX + moonOrbitRadius * Math.cos(moonTheta);
const moonY = earthY + moonOrbitRadius * Math.sin(moonTheta);
// handling traversal of the earths orbit
// handling traversed length on the moons orbit
const orbitCircumference = 2 * Math.PI * moonOrbitRadius;
// Moon orbit fraction
// Define the colors for the completed and remaining paths
const completedOrbitColor = 'green';
const remainingOrbitColor = 'lightgray';
// Calculate the completed fraction of the orbit
// Calculate angle from the 12 o'clock position in radians and convert to degrees
let angleFromHorizontal = Math.atan2(moonY - earthY, moonX - earthX) * 180 / Math.PI;
// Adjust the angle for the clock face
let angleFromTop = 90 + angleFromHorizontal;
// Normalize the angle to be between 0 and 360 degrees
const normalizedAngle = (angleFromTop + 360) % 360;
 // Calculate the length of the path that has been traversed
 const traversedPathLength = normalizedAngle / 360 * orbitCircumference;
 const remainingPathLength = orbitCircumference - traversedPathLength;
// handling e phases on the moon-----------------------------
// Calculate the angle between the Moon and the Sun from Earth's perspective
const sunAngle = Math.atan2(sunCenter.y - earthY, sunCenter.x - earthX);
const moonAngle = Math.atan2(moonY - earthY, moonX - earthX);
// // Normalize angles to be within 0 to 2π
const normalizeAngle = angle => (angle + 2 * Math.PI) % (2 * Math.PI);
// Calculate phaseAngle
const phaseAngle = normalizeAngle(moonAngle - sunAngle);
// Check for special cases with a more precise threshold
const eclipseThreshold = 0; // Adjust this threshold as needed
const isLunarEclipse = Math.abs(phaseAngle) < eclipseThreshold || Math.abs(phaseAngle - 2 * Math.PI) < eclipseThreshold;
const isNewMoon = Math.abs(phaseAngle - Math.PI) < eclipseThreshold;
// Update illuminated fraction based on special cases
let illuminatedFraction = isLunarEclipse || isNewMoon ? 0 : (1 + Math.cos(phaseAngle)) / 2;

// Calculate the angle for the gradient rotation--tidal locking
const angleToSun = Math.atan2(sunCenter.y - moonY, sunCenter.x - moonX);
const gradientRotationDegrees = (angleToSun) * (180 / Math.PI)+113.5;
// Correct the gradient transformation
const gradientTransform = `rotate(${gradientRotationDegrees}, 0.5, 0.5)`;
const spread = calculateGradientSpread(angleToSun); // You need to define this function
  return(
    <g>
      <defs>
        {/* shadow on the moon */}
        <linearGradient id="moonPhaseGradient" x1="0" x2={spread} y1="0" y2={spread} gradientTransform={gradientTransform}>
        <stop offset={`${illuminatedFraction}`} stopColor="white" />
        <stop offset={`${illuminatedFraction}`} stopColor="gray" />
    </linearGradient>
    </defs>
<circle cx={earthX} cy={earthY} r={moonOrbitRadius} fill="none"  stroke={remainingOrbitColor} 
  strokeWidth="2" />
{/* handling the traversal visualization */}

<circle 
cx={earthX} 
cy={earthY} 
r={moonOrbitRadius} 
fill="none" 
stroke={completedOrbitColor} 
strokeWidth="2" 
strokeDasharray={`${traversedPathLength} ${remainingPathLength}`}
strokeDashoffset={orbitCircumference / 4} // Offset to start from the 12 o'clock position
/>
{/* Moon gradient */}
      <circle cx={moonX} cy={moonY} r={moonRadius} fill="url(#moonPhaseGradient)" />
    </g>
  );
}//end of moon






// placing a compass
function Compass(){
  const { sunCenter, earthOrbitRadius } = useContext(SvgDimensionsContext); 
   const compassSize = 50; // Adjust as needed
const center = { x: sunCenter.x+(earthOrbitRadius/1.1), y: earthOrbitRadius/1.8 }; // Center of the SVG
const northLabelOffset = 15; // Offset for the 'N' label from the arrowhead

  return(
<g>
    {/* Compass Lines */}
    <line x1={center.x} y1={center.y} x2={center.x} y2={center.y + compassSize / 2} stroke="red" strokeWidth="2"/>
    <line x1={center.x - (compassSize / 2)+15} y1={center.y} x2={center.x + (compassSize / 2)-15} y2={center.y} stroke="white" strokeWidth="2"/>

    {/* Arrowhead for North */}
    <polygon points={`${center.x},${center.y - compassSize / 2} ${center.x - 5},${center.y - (compassSize / 2) + 10} ${center.x + 5},${center.y - (compassSize / 2) + 10}`} fill="red"/>

    {/* 'N' Label */}
    <text x={center.x} y={center.y - compassSize / 2 - northLabelOffset} textAnchor="middle" fill="white">N</text>
</g>
  );
}


function calculateGradientSpread(angleToSun) {
  // Normalize the angle between 0 and PI/2 (0 and 90 degrees)
  // The angle is mirrored for the other half of the Moon's orbit
  const normalizedAngle = Math.min(Math.PI / 2, Math.abs(angleToSun % (Math.PI / 2)));
  // Calculate the spread of the gradient
  // This is a simple linear interpretation; it could be more complex for more realism
  const spread = 0.5 + 0.5 * Math.cos(normalizedAngle); // Adjusts between 0.5 (crescent) and 1.0 (full moon)

  return spread;
}


