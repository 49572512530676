import React, { useContext, useMemo } from 'react';
import { SvgDimensionsContext } from './dimensia'; // Adjust path as needed
import { generateStars } from './starGenerator';

function isNight(currentTime) {
    const hours = currentTime.getHours();
    return hours < 6 || hours >= 18; // Night is from 6 PM to 6 AM
}


function Stars({currentTime}) {
    const { svgWidth, svgHeight, containerCenter, earthOrbitRadius, moonOrbitRadius } = useContext(SvgDimensionsContext);
    const minRadius = earthOrbitRadius + moonOrbitRadius + 10;
    const starstogenerate=50;

    // Memoize the star generation
    const stars = useMemo(() => {
        if (isNight(currentTime)) {
            return generateStars(starstogenerate, minRadius, containerCenter, svgWidth, svgHeight);
        }
        return [];
    }, [containerCenter, minRadius, svgWidth, svgHeight]);

    return (
        <g>
            {stars.map((star, index) => (
                <polygon
                    key={index}
                    points={star.points}
                    fill="white"
                    className="star" // Class for CSS animation
                    transform={`translate(${star.cx}, ${star.cy})`} // Position the star
                />
            ))}
        </g>
    );
}

export default Stars;


